import * as types from './abiActionTypes';

export const abiRequestAction = (payload) => {
  return {
    type: types.ABI_REQUEST,
    payload,
  };
};
export const abiDataAction = (payload) => {
  return {
    type: types.ABI_DATA,
    payload,
  };
};
export const abiErrorAction = (payload) => {
  return {
    type: types.ABI_ERROR,
    payload,
  };
};
export const createContractActionRequest = (payload) => {
  return {
    type: types.CREATE_CONTRACT_REQUEST,
    payload,
  };
};

export const createContractActionData = (payload) => {
  return {
    type: types.CREATE_CONTRACT_DATA,
    payload,
  };
};

export const createContractActionError = (payload) => {
  return {
    type: types.CREATE_CONTRACT_ERROR,
    payload,
  };
};

export const createContractActionSuccess = (payload) => {
  return {
    type: types.CREATE_CONTRACT_SUCCESS,
    payload,
  };
};

export const updateContractActionRequest = (payload) => {
  return {
    type: types.UPDATE_CONTRACT_REQUEST,
    payload,
   };
 };

export const updateContractActionData = (payload) => {
  return {
    type: types.UPDATE_CONTRACT_DATA,
    payload,
  };
};

export const updateContractActionError = (payload) => {
  return {
    type: types.UPDATE_CONTRACT_ERROR,
    payload,
  };
};

export const updateContractActionSuccess = (payload) => {
  return {
    type: types.UPDATE_CONTRACT_SUCCESS,
    payload,
  };
};
