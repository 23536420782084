import {
  BACKOFFICETRANSACTIONS_SORT_KEYS,
  BACKOFFICETRANSACTIONS_SORT_TYPES,
  backofficeTransactionsQuickFilterConstants,
  sortKeys,
} from '../../pages/BackofficeTransactions/backofficeTransactionsConstants';
import * as types from './bo_transactionActionTypes';

const initialState = {
  backofficeTransactions: null,
  filteredBackofficeTransactions: null,
  backofficeTransactionsSortData: {
    sortType: BACKOFFICETRANSACTIONS_SORT_TYPES.ASCENDING,
    sortKey: BACKOFFICETRANSACTIONS_SORT_KEYS.PROJECT_NAME,
  },
  quickFilter: 1,
  error: {
    type: null,
    data: null,
  },
  success: null,
};

export const backofficeTransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TRANSACTION_DATA:
      return {
        ...state,
        success: action.success.result ? Object.assign({}, action.success.result) : null,
      };
    case types.TRANSACTION_ERROR:
      return {
        ...state,
        error: {
          type: types.TRANSACTION_ERROR,
          data: action.payload,
        },
      };
    case types.GET_BACKOFFICETRANSACTIONS_DATA:
      let backofficeTransactions = action.payload.result
      return {
        ...state,
        backofficeTransactions: backofficeTransactions ? [...backofficeTransactions] : null,
      };

    case types.GET_BACKOFFICETRANSACTIONS_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_BACKOFFICETRANSACTIONS_ERROR,
          data: action.payload,
        },
      };
    case types.FILTER_BACKOFFICETRANSACTIONS:
      function filterBackofficeTransactions(filterInput) {
        console.log(filterInput)
        const filteredBackofficeTransactions = [];


        let tempBackofficeTransactionsForFilter = state.backofficeTransactions;
        // console.log('Filtered BackofficeTransactions:', tempBackofficeTransactionsForFilter);

        if (state.quickFilter === backofficeTransactionsQuickFilterConstants.TRUE) {
          tempBackofficeTransactionsForFilter = 
          tempBackofficeTransactionsForFilter.filter((t) => t.transaction_status === true);
        } else if (state.quickFilter === backofficeTransactionsQuickFilterConstants.FALSE) {
          tempBackofficeTransactionsForFilter =
           tempBackofficeTransactionsForFilter.filter((t) => t.transaction_status === false);
        }


        if (tempBackofficeTransactionsForFilter) {
          for (const fBackofficeTransactions of tempBackofficeTransactionsForFilter) {
            if (
              fBackofficeTransactions?.transaction_hash?.toString()
              .toLowerCase().includes(filterInput) ||
              fBackofficeTransactions?.project_name?.toString()
              .toLowerCase().includes(filterInput) ||
              fBackofficeTransactions?.user_public_address?.toString()
              .toLowerCase().includes(filterInput)
            ) {
              filteredBackofficeTransactions.push(fBackofficeTransactions);
            }
          }

          return filteredBackofficeTransactions;
        }
      }

      let filteredBackofficeTransactions = null;
      filteredBackofficeTransactions = 
      filterBackofficeTransactions(action?.payload?.toString().toLowerCase());

      return {
        ...state,
        filteredBackofficeTransactions: filteredBackofficeTransactions ? 
        [...filteredBackofficeTransactions] : null,
      };
    case types.SET_BACKOFFICETRANSACTIONS_SORT_DATA:
      return {
        ...state,
        backofficeTransactionsSortData: { ...action.payload },
      };
    case types.SORT_BACKOFFICETRANSACTIONS:
      const selectedBackofficeTransactionKey =
       sortKeys[state.backofficeTransactionsSortData.sortKey].key;
      const tempBackofficeTransactionsForSorting = 
      state.filteredBackofficeTransactions?.length
        ? state.filteredBackofficeTransactions
        : state.backofficeTransactions;
      console.log("Temp BackofficeTransactions For Sorting:", tempBackofficeTransactionsForSorting);

      const sortedBackofficeTransactions = 
      tempBackofficeTransactionsForSorting?.sort((a, b) => {
        if (state.backofficeTransactionsSortData.sortType ===
           BACKOFFICETRANSACTIONS_SORT_TYPES.ASCENDING) {
          return (
            (selectedBackofficeTransactionKey === 'date' ?
              new Date(a.cdate) -
               new Date(b.cdate) :
              a[selectedBackofficeTransactionKey]
                ?.toString()
                .toLowerCase()
                .localeCompare(b[selectedBackofficeTransactionKey]
                  ?.toString().toLowerCase())
            )
          );
        } else {
          return (
            (selectedBackofficeTransactionKey === 'date' ?
              new Date(b.cdate) - new Date(a.cdate) :
              b[selectedBackofficeTransactionKey]
                ?.toString()
                .toLowerCase()
                .localeCompare(a[selectedBackofficeTransactionKey]
                  ?.toString().toLowerCase())
            )
          );
        }
      });

      if (state.filteredBackofficeTransactions?.length) {
        return {
          ...state,
          filteredBackofficeTransactions: sortedBackofficeTransactions ?
           [...sortedBackofficeTransactions] : null,
        };
      }
      return {
        ...state,
        backofficeTransactions: sortedBackofficeTransactions ?
         [...sortedBackofficeTransactions] : state.backofficeTransactions,
      };

    case types.UPDATE_QUICK_FILTER:
      return {
        ...state,
        quickFilter: action?.payload,
      };
    default:
      return state;
  }
};

