import React from 'react';
import './ContractEdit.scss';
import { connect } from 'react-redux';
import MercurXBackButton from '../../components/MercurXBackButton/MercurXBackButton';
import MercurXContractDetail from '../../components/MercurXContractDetail/MercurXContractDetail';
function ContractEdit({ ...props }) {

    return (
      <div>
        <MercurXBackButton buttonText="Back to contracts list" />
        <div className="text-fs-head-sm">Contract Detail</div>
        <MercurXContractDetail/>
      </div>
    );
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractEdit);
