import {
  LAUNCHPADUSERS_SORT_KEYS,
  LAUNCHPADUSERS_SORT_TYPES,
  launchpadUsersQuickFilterConstants,
  sortKeys,
} from '../../pages/LaunchpadUsers/launchpadUsersConstants';
import * as types from './launchpadUserActionTypes';

const initialState = {
  launchpadUsers: null,
  filteredLaunchpadUsers: null,
  launchpadUsersSortData: {
    sortType: LAUNCHPADUSERS_SORT_TYPES.ASCENDING,
    sortKey: LAUNCHPADUSERS_SORT_KEYS.FULL_NAME,
  },
  quickFilter: 1,
  error: {
    type: null,
    data: null,
  },
};

export const launchpadUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LAUNCHPADUSERS_DATA:
      let launchpadUsers = action.payload.result;
      return {
        ...state,
        launchpadUsers: launchpadUsers ? [...launchpadUsers] : null,
      };
    case types.GET_LAUNCHPADUSERS_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_LAUNCHPADUSERS_ERROR,
          data: action.payload,
        },
      };
    case types.FILTER_LAUNCHPADUSERS:
      function filterLaunchpadUsers(filterInput) {
        console.log(filterInput)
        const filteredLaunchpadUsers = [];

        let tempLaunchpadUsersForFilter = state.launchpadUsers;
        console.log('Filtered LaunchpadUsers:', tempLaunchpadUsersForFilter);

        if (state.quickFilter === launchpadUsersQuickFilterConstants.TRUE) {
          tempLaunchpadUsersForFilter = tempLaunchpadUsersForFilter.filter((t) =>
           t.is_active === "True");
        } else if (state.quickFilter === launchpadUsersQuickFilterConstants.FALSE) {
          tempLaunchpadUsersForFilter = tempLaunchpadUsersForFilter.filter((t) =>
           t.is_active !== "True");
        }

        if (tempLaunchpadUsersForFilter) {
          for (const fLaunchpadUsers of tempLaunchpadUsersForFilter) {
            if (
              fLaunchpadUsers?.full_name?.toString().toLowerCase().includes(filterInput) ||
              fLaunchpadUsers?.email?.toString().toLowerCase().includes(filterInput)
            ) {
              filteredLaunchpadUsers.push(fLaunchpadUsers);
            }
          }

          return filteredLaunchpadUsers;
        }
      }

      let filteredLaunchpadUsers = null;
    
      filteredLaunchpadUsers = filterLaunchpadUsers(action?.payload?.toString().toLowerCase());

      return {
        ...state,
        filteredLaunchpadUsers: filteredLaunchpadUsers ? [...filteredLaunchpadUsers] : null,
      };
    case types.SET_LAUNCHPADUSERS_SORT_DATA:
      return {
        ...state,
        launchpadUsersSortData: { ...action.payload },
      };
      case types.SORT_LAUNCHPADUSERS:
        const selectedKey = sortKeys[state.launchpadUsersSortData.sortKey].key;
        const tempLaunchpadUserForSorting = state.filteredLaunchpadUsers?.length
          ? state.filteredLaunchpadUsers
          : state.launchpadUsers;
          console.log("Temp Locks For Sorting:", tempLaunchpadUserForSorting);
  
          const sortedLaunchpadUsers = tempLaunchpadUserForSorting?.sort((a, b) => {
         
            const fullNameComparison = a[selectedKey]
              ?.toString()
              .toLowerCase()
              .localeCompare(b[selectedKey]?.toString().toLowerCase());
          
              const fullNameComparisonDesc = b[selectedKey]
              ?.toString()
              .toLowerCase()
              .localeCompare(a[selectedKey]?.toString().toLowerCase());
          
            if (state.launchpadUsersSortData.sortType === LAUNCHPADUSERS_SORT_TYPES.ASCENDING) {
              if (selectedKey === 'full_name') {
                return fullNameComparison;
              } else {
                return (
                  
                  a.email
                    ?.toString()
                    .toLowerCase()
                    .localeCompare(b.email?.toString().toLowerCase())
                );
              }
            } else {
              
              if (selectedKey === 'full_name') {
                return fullNameComparisonDesc;
              } else {
                return (

                  b.email
                    ?.toString()
                    .toLowerCase()
                    .localeCompare(a.email?.toString().toLowerCase())
                );
              }
            }
          });
          
      if (state.filteredLaunchpadUsers?.length) {
        return {
          ...state,
          filteredLaunchpadUsers: sortedLaunchpadUsers ? [...sortedLaunchpadUsers] : null,
        };
      }
      return {
        ...state,
        launchpadUsers: sortedLaunchpadUsers ? [...sortedLaunchpadUsers] : state.launchpadUsers,
      };
    case types.UPDATE_QUICK_FILTER:
      return {
        ...state,
        quickFilter: action?.payload,
      };
    default:
      return state;
  }
};
