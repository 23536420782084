import { doDelete, doGet, doPost, doPut } from './axiosCrud';

//User
export const login = (body) => doPost('/bo_login', body);
export const checkUserToken = () => doGet('/bo_user/check_token');
export const getLaunchpadUsers = () => doGet('/users');
export const getBackofficeUsers = () => doGet('/bo_users');
export const createBackofficeUser = (body) => doPost('/bo_create_user',body);
export const updateBackofficeUser = (userID,body) => doPut('/bo_user/' + userID,body);
export const activation = (body) => doPost('/bo_activate', body);
export const resendVerificationEmail = () => doPost('/user/bo_activation_email');
export const forgotPassword = (body) => doPost('/bo_password-recovery', body);
export const resetPassword = (body) => doPost('/bo_reset-password', body);
//Projects
export const getProjects = () => doGet('/projects');
export const createProject = (body) => doPost('/projects',body);
export const updateProject = (projectID,body) => doPut('/projects/' + projectID,body);
export const deleteProject = (projectID) => doDelete('/projects/'+ projectID);

//Tokens
export const getTokens = () => doGet('/tokens');
export const createToken = (body) => doPost('/tokens',body);
export const updateToken = (tokenID,body) => doPut('/tokens/' + tokenID,body);
export const deleteToken = (tokenID) => doDelete('/tokens/' + tokenID);

//Transactions
export const getTransactions = () => doGet('/transaction');
export const getBoTransaction = () => doGet('/bo_transaction');
export const boTransaction = (body) => doPost('/bo_transaction', body);
//Locks
export const getLocks = () => doGet('/locking/history');
export const getStakes = () => doGet('/staking/history');

export const getWalletHistory = (wallet) => doGet('/user/wallet_history/' + wallet);
export const newtorkChain = (chainId) => doPost('/chain/' + chainId);
export const getTier = (id) => doGet('/tier/' + id);
export const putTier = (id) => doPut('/tier/' + id);


export const GetAbiList = (body) => doGet('/contracts', body);
export const createContract = (formData) => 
  doPost('/contracts', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
export const updateContract = (id, formData) =>
  doPut(`/contracts/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });


export const getBNB = () => doGet('/bnb');

//default
export const enable2fa = (totp_code) => doPost('/bo_enable_2fa/' + `${totp_code}`);
export const getGenerateQR = () => doGet('/bo_generate_qr');
export const verify2fa = (totp_code) => doPost('/bo_verify_2fa/'
 + `${totp_code}`);
 export const create2fa = () => doPost('/bo_create_2fa');