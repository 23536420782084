import {
  TOKENS_SORT_KEYS,
  TOKENS_SORT_TYPES,
  // tokensQuickFilterConstants,
  sortKeys,
} from '../../pages/Tokens/tokensConstants';
import * as types from './tokenActionTypes';

const initialState = {
  tokens: null,
  filteredTokens: null,
  token: null,
  createdToken: null,
  updatedToken: null,
  deletedToken: null,
  tokensSortData: {
    sortType: TOKENS_SORT_TYPES.ASCENDING,
    sortKey: TOKENS_SORT_KEYS.TOKEN_NAME,
  },
  quickFilter: 1,
  error: {
    type: null,
    data: null,
  },
};

export const tokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TOKENS_DATA:
      let tokens = action.payload.result;
      return {
        ...state,
        tokens: tokens ? [...tokens] : null,
      };
    case types.GET_TOKENS_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_TOKENS_ERROR,
          data: action.payload,
        },
      };
    case types.CREATE_TOKEN_DATA:
      return {
        ...state,
        createdProjet: action.payload,
      };
    case types.CREATE_TOKEN_ERROR:
      return {
        ...state,
        error: {
          type: types.CREATE_TOKEN_ERROR,
          data: action.payload,
        },
      };
    case types.UPDATE_TOKEN_DATA:
      return {
        ...state,
        updatedToken: action.payload,
      };
    case types.UPDATE_TOKEN_ERROR:
      return {
        ...state,
        error: {
          type: types.UPDATE_TOKEN_ERROR,
          data: action.payload,
        },
      };
    case types.DELETE_TOKEN_DATA:
      const deletedTokenID = action.payload;

      let tempTokensForDelete = state.tokens;
      const deletedTokenIndex = tempTokensForDelete.findIndex(
        (p) => p.id === deletedTokenID,
      );
      tempTokensForDelete.splice(deletedTokenIndex, 1);

      let tempFilteredTokenForDelete = state.filteredTokens;
      if (tempFilteredTokenForDelete) {
        const deletedFilteredTokenIndex = tempFilteredTokenForDelete.findIndex(
          (p) => p.id === deletedTokenID,
        );
        tempFilteredTokenForDelete.splice(deletedFilteredTokenIndex, 1);
      }

      return {
        ...state,
        tokens: tempTokensForDelete ? [...tempTokensForDelete] : state.tokens,
        filteredTokens: tempFilteredTokenForDelete ? [...tempFilteredTokenForDelete] : null,
        deletedToken: action.payload,
      };
    case types.DELETE_TOKEN_ERROR:
      return {
        ...state,
        error: {
          type: types.DELETE_TOKEN_ERROR,
          data: action.payload,
        },
      };
    // case types.FILTER_TOKENS:
    //   function filterTokens(filterInput) {
    //     const filteredTokens = [];

    //     let tempTokensForFilter = state.tokens;
    //     if (state.quickFilter === tokensQuickFilterConstants.ACTIVE) {
    //       tempTokensForFilter = tempTokensForFilter.filter((t) => t.is_active === 'active');
    //     } else if (state.quickFilter === tokensQuickFilterConstants.COMPLETED) {
    //       tempTokensForFilter = tempTokensForFilter.filter((t) => t.is_active === 'completed');
    //     }

    //     if (tempTokensForFilter) {
    //       for (const fTokens of tempTokensForFilter) {
    //         if (
    //           fTokens?.token?.name?.toString().toLowerCase().includes(filterInput) ||
    //           fTokens?.token?.symbol?.toString().toLowerCase().includes(filterInput) ||
    //           fTokens?.token?.address?.toString().toLowerCase().includes(filterInput)
    //         ) {
    //           filteredTokens.push(fTokens);
    //         }
    //       }

    //       return filteredTokens;
    //     }
    //   }
    case types.FILTER_TOKENS:
      function filterTokens(filterInput) {
        const filteredTokens = [];

        let tempTokensForFilter = state.tokens;

        if (tempTokensForFilter) {
          for (const fTokens of tempTokensForFilter) {
            if (
              fTokens?.name?.toString().toLowerCase().includes(filterInput) ||
              fTokens?.symbol?.toString().toLowerCase().includes(filterInput) ||
              fTokens?.address?.toString().toLowerCase().includes(filterInput)
            ) {
              filteredTokens.push(fTokens);
            }
          }

          return filteredTokens;
        }
      }

      let filteredTokens = null;
      filteredTokens = filterTokens(action?.payload?.toString().toLowerCase());

      return {
        ...state,
        filteredTokens: filteredTokens ? [...filteredTokens] : null,
      };
    case types.SET_TOKENS_SORT_DATA:
      return {
        ...state,
        tokensSortData: { ...action.payload },
      };
    // case types.SORT_TOKENS:
    //   const selectedKey = sortKeys[state.tokensSortData.sortKey].key;
    //   const tempTokensForSorting = state.filteredTokens?.length
    //     ? state.filteredTokens
    //     : state.tokens;
    //   const sortedTokens = tempTokensForSorting?.sort((a, b) => {
    //     if (state.tokensSortData.sortType === TOKENS_SORT_TYPES.ASCENDING) {
    //       return (
    //         a.is_active.localeCompare(b.is_active) ||
    //         a.token[selectedKey]
    //           ?.toString()
    //           .toLowerCase()
    //           .localeCompare(b.token[selectedKey]?.toString().toLowerCase())
    //       );
    //     } else {
    //       return (
    //         a.is_active.localeCompare(b.is_active) ||
    //         b.token[selectedKey]
    //           ?.toString()
    //           .toLowerCase()
    //           .localeCompare(a.token[selectedKey]?.toString().toLowerCase())
    //       );
    //     }
    //   });
    case types.SORT_TOKENS:
    
      const selectedKey = sortKeys[state.tokensSortData.sortKey].key;
      const tempTokensForSorting = state.filteredTokens?.length
        ? state.filteredTokens
        : state.tokens;
        
      const sortedTokens = tempTokensForSorting?.sort((a, b) => {
        if (state.tokensSortData.sortType === TOKENS_SORT_TYPES.ASCENDING) {
          return a[selectedKey]
            ?.toString()
            .toLowerCase()
            .localeCompare(b[selectedKey]?.toString().toLowerCase());
        } else {
          return b[selectedKey]
            ?.toString()
            .toLowerCase()
            .localeCompare(a[selectedKey]?.toString().toLowerCase());
        }
      });

      if (state.filteredTokens?.length) {
        return {
          ...state,
          filteredTokens: sortedTokens ? [...sortedTokens] : null,
        };
      }
      return {
        ...state,
        tokens: sortedTokens ? [...sortedTokens] : state.tokens,
      };
    case types.UPDATE_QUICK_FILTER:
      return {
        ...state,
        quickFilter: action?.payload,
      };
    default:
      return state;
  }
};
