import React, {  useState } from 'react';
import './Contracts.scss';
import { connect } from 'react-redux';
import MercurXContractTable from '../../components/MercurXContractTable/MercurXContractTable';
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function Contracts({ ...props }) {
  const {
    
  } = props;

  const history = useHistory();
  const [filterInput, setFilterInput] = useState('');
  const user = JSON.parse(localStorage.getItem('user'))?.user;
 

  return (
    <>
      <div className="text-fs-head-md mb-4">Contracts</div>
      <Row className="text-fs-body-md mt-4 text-center">
        <Col>
          <Form.Control
            className="text-fs-body-sm"
            type="text"
            placeholder="Search by abi name..."
            aria-label="text"
            aria-describedby="basic-addon1"
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
            size={'sm'}
          />
        </Col>
      </Row>
      <Row
        id="projects-container"
        className="d-flex align-items-center justify-content-between mt-4"
      >
      </Row>
      <div id="projects-container">
      <MercurXContractTable filterInput={filterInput} />
      </div>
      
    </>
  );
}
const mapStateToProps = (state) => {
  return {
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contracts);
