/* eslint-disable max-len */
import { put, all, call, takeEvery } from 'redux-saga/effects';
import * as types from './abiActionTypes';
import * as actions from './abiActions';
import * as alert from '../alert/alertActions';
import * as endpoints from '../../services/endpoints';

function* abiHistory() {
  try {
    const res = yield call(endpoints.GetAbiList);
    console.log('res')
    console.log(res)
    yield put(actions.abiDataAction(res.data));
  } catch (e) {
    console.error(e);
    yield put(actions.abiErrorAction(e));
  }
}

function* createContractSaga(action) {
  try {
    const formData = action.payload;
    const { data } = yield call(endpoints.createContract, formData);

    yield put(actions.createContractActionSuccess());

    yield put(actions.abiRequestAction());

    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: data?.message,
        variant: 'success',
      })
    );
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.message,
        variant: 'danger',
        outTimeMS: 6000,
      })
    );
    yield put({ type: types.CREATE_CONTRACT_ERROR, payload: e });
  }
}


function* updateContractSaga(action) {
  try {
    console.log('action')
    console.log(action)
    console.log(action.payload)
    const { id, formData } = action.payload;
    const { data } = yield call(endpoints.updateContract, id, formData);

    yield put(actions.updateContractActionSuccess());
    
    yield put(actions.abiRequestAction());

    yield put(
      alert.setAlertAction({
        title: "Success!",
        text: data?.message,
        variant: "success",
      })
    );
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: "Error!",
        text: e?.response?.data?.message,
        variant: "danger",
        outTimeMS: 6000,
      })
    );
    yield put({ type: types.UPDATE_CONTRACT_ERROR, payload: e });
  }
}


function* watchAbiHistory() {
  yield takeEvery(types.ABI_REQUEST, abiHistory);
}
function* watchCreateContract() {
  yield takeEvery(types.CREATE_CONTRACT_REQUEST, createContractSaga);
}
function* watchUpdateContract() {
  yield takeEvery(types.UPDATE_CONTRACT_REQUEST, updateContractSaga);
}


export function* abiSaga() {
  yield all([
    watchAbiHistory(),
    watchCreateContract(),
    watchUpdateContract()
  ]);
}
