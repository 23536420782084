import * as types from './abiActionTypes';

const initialState = {

  abiHistory: null,
  error: {
    type: null,
    data: null,
  },
};

export const abiReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.ABI_DATA:
      return {
        ...state,

        abiHistory: action?.payload.result ? Object.assign([], action.payload.result) : null,
      };
    case types.ABI_ERROR:
      return {
        ...state,

        error: { type: types.ABI_ERROR, data: action.payload },
      };
    case types.UPDATE_CONTRACT_SUCCESS:
      return {
        ...state,
        abiHistory: state.abiHistory.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        ),
      };
      case types.CREATE_CONTRACT_SUCCESS:
        return {
          ...state,
          abiHistory: state.abiHistory ? [...state.abiHistory, action.payload] : [action.payload],
        };      
    default:
      return state;
  }
};
