import { useEffect, useState } from 'react';
import { Pagination, Table, Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import './MercurXContractTable.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { abiRequestAction } from '../../store/abi/abiActions';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
import { mainColors } from '../../helpers/colors';
import {
  createContractActionRequest,
  updateContractActionRequest,
} from '../../store/abi/abiActions';
import Swal from 'sweetalert2';

function MercurXContractTable(props) {
  const { abiHistory, abiHistoryRequest, filterInput,
    createContractRequest,
    updateContractRequest, } = props;
  const [filteredData, setFilteredData] = useState([]);
  const history = useHistory();
  const maxRowCountPerPage = 5;
  const maxShowingPage = 5;
  const [sourceData, setSourceData] = useState(abiHistory);
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [renderedItems, setRenderedItems] = useState([]);
  const [renderedPages, setRenderedPages] = useState([]);
  const [lastPageOfPagination, setLastPageOfPagination] = useState(maxShowingPage);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCreateFile, setSelectedCreateFile] = useState(null);
   // Upload için state değişkenleri
   const [selectedAbi, setSelectedAbi] = useState(null);
   const [selectedCode, setSelectedCode] = useState(null);
   const [selectedByteCode, setSelectedByteCode] = useState(null);
   const [showModal, setShowModal] = useState(false);
   const [selectedItemId, setSelectedItemId] = useState(null);
 
   // Create için state değişkenleri
   const [showCreateModal, setShowCreateModal] = useState(false);
   const [selectedCreateAbi, setSelectedCreateAbi] = useState(null);
   const [selectedCreateCode, setSelectedCreateCode] = useState(null);
   const [selectedCreateByteCode, setSelectedCreateByteCode] = useState(null);
 
  useEffect(() => {
    abiHistoryRequest();
  }, []);

  useEffect(() => {
    if (abiHistory) {
      let filteredData = Object.keys(abiHistory).map((key) => ({
        name: key,
        data: abiHistory[key],
      }));

      if (filterInput.trim() !== "") {
        filteredData = filteredData.filter((item) =>
          item.name.toLowerCase().includes(filterInput.toLowerCase())
        );
      }

      setSourceData(filteredData);
    }
  }, [abiHistory, filterInput]);




  useEffect(() => {
    if (sourceData) {
      setCurrentPage(1);
      const tempPages = [];
      for (let index = 0; index < Math.ceil(sourceData.length / maxRowCountPerPage); index++) {
        tempPages.push(index + 1);
      }
      setPages([...tempPages]);
      setRenderedPages([
        ...tempPages.slice(lastPageOfPagination - maxShowingPage, lastPageOfPagination),
      ]);
    }
  }, [sourceData]);

  useEffect(() => {
    if (sourceData && currentPage) {
      const firstIndex = maxRowCountPerPage * currentPage - maxRowCountPerPage;
      const lastIndex = maxRowCountPerPage * currentPage;
      const tempRendered = sourceData?.slice(firstIndex, lastIndex);
      setRenderedItems([...tempRendered]);
    }
  }, [currentPage, sourceData]);

  function scrollToContainer() {
    const container = document.getElementById('data-container');
    if (container) {
      container.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const setPaginationPages = (page) => {
    if (
      renderedPages.findIndex((p) => p === page + 1) === -1 &&
      pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination + 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1),
      );
    } else if (
      renderedPages.findIndex((p) => p === page - 1) === -1 &&
      pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination - 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1),
      );
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
    if (page === lastPageOfPagination) {
      setPaginationPages(page);
    } else if (page === lastPageOfPagination - maxShowingPage + 1) {
      setPaginationPages(page);
    }
    scrollToContainer();
  };

  const firstPage = () => {
    setCurrentPage(1);
    setLastPageOfPagination(maxShowingPage);
    setRenderedPages(pages.slice(0, maxShowingPage));
    scrollToContainer();
  };

  const prevPage = () => {
    if (currentPage - 1 !== 0) {
      setCurrentPage(currentPage - 1);
      setPaginationPages(currentPage - 1);
      scrollToContainer();
    }
  };

  const nextPage = () => {
    if (currentPage + 1 <= pages.length) {
      setCurrentPage(currentPage + 1);
      setPaginationPages(currentPage + 1);
      scrollToContainer();
    }
  };

  const lastPage = () => {
    setCurrentPage(pages.length);
    if (pages.length > maxShowingPage) {
      setLastPageOfPagination(pages.length);
      setRenderedPages(pages.slice(pages.length - maxShowingPage, pages.length));
    }
    scrollToContainer();
  };
  const handleNavigate = (itemName) => {
    history.push('/mercurx-contract', { itemName });
  };
  const handleNavigateEdit = (itemName, item) => {
    history.push('/contract-edit', { itemName, item });
  };
  const handleOpenModal = (itemId) => {
    setSelectedItemId(itemId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedFile(null);
    setShowModal(false);
  };
  const handleAbiChange = (event) => setSelectedAbi(event.target.files[0]);
  const handleCodeChange = (event) => setSelectedCode(event.target.files[0]);
  const handleByteCodeChange = (event) => setSelectedByteCode(event.target.files[0]);

  const handleUpload = () => {
    if (!selectedAbi && !selectedCode && !selectedByteCode) {
      Swal.fire({
        icon: 'warning',
        iconColor: mainColors.warning,
        text: 'Please select at least one file!',
        showCancelButton: true,
      });
      return;
    }
  
    const formData = new FormData();
  
    if (selectedAbi) formData.append("abi", selectedAbi);
    if (selectedCode) formData.append("code", selectedCode);
    if (selectedByteCode) formData.append("byte_code", selectedByteCode);
  
    const payload = {
      id: selectedItemId,
      formData: formData,
    };
  
    updateContractRequest(payload);
    handleCloseModal();
  };
  

  const handleOpenCreateModal = () => setShowCreateModal(true);
  const handleCloseCreateModal = () => {
    setSelectedCreateAbi(null);
    setSelectedCreateCode(null);
    setSelectedCreateByteCode(null);
    setShowCreateModal(false);
  };

  const handleCreateAbiChange = (event) => setSelectedCreateAbi(event.target.files[0]);
  const handleCreateCodeChange = (event) => setSelectedCreateCode(event.target.files[0]);
  const handleCreateByteCodeChange = (event) => setSelectedCreateByteCode(event.target.files[0]);

  const handleCreateUpload = () => {
    if (!selectedCreateAbi || !selectedCreateCode || !selectedCreateByteCode) {
      Swal.fire({
        icon: 'warning',
        iconColor: mainColors.warning,
        text: 'Please select all files!',
        showCancelButton: true,
      });
      return;
    }
  
    const formData = new FormData();
    formData.append("abi", selectedCreateAbi);
    formData.append("code", selectedCreateCode);
    formData.append("byte_code", selectedCreateByteCode);
  
    createContractRequest(formData);
    handleCloseCreateModal();
  };
  
  const user = JSON.parse(localStorage.getItem('user'))?.user;
  return (
    <>
      <Table className="projects-table-design" responsive hover borderless>
        <thead>
          <tr className="text-t-body-color text-fs-tag">
            <th>#</th>
            <th>Abi Name</th>
            <th>Run</th>
            <th>Detail</th>
            <th>Upload</th>
          </tr>
        </thead>
        {renderedItems?.length ? (
          <tbody>
            {renderedItems?.map((item, index) => (
              <tr key={index} className="text-t-head-color">
                <td>{index + 1}</td>
                <td>{item.name}</td>
                {/* <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleNavigate(item.name)}
                  >
                    Open
                  </button>
                </td> */}
                <td>
                  {user?.is_superuser && (
                    <div className="cursor-pointer mt-1"
                      onClick={() => handleNavigate(item.name)}>
                      <MercurXIcons
                        name="BsSkipForwardBtnFill"
                        size={24}
                        color={mainColors['primary']}
                      />
                    </div>
                  )}
                </td>
                <td>
                  {user?.is_superuser && (
                    <div className="cursor-pointer mt-1"
                      onClick={() => handleNavigateEdit(item.name, item)}>
                      <MercurXIcons
                        name="BsFillInfoSquareFill"
                        size={24}
                        color={mainColors['primary']}
                      />
                    </div>
                  )}
                </td>
                <td>
                  <div className="cursor-pointer mt-1"
                    onClick={() => handleOpenModal(item.data.id)}>
                    <MercurXIcons
                      name="BsUpload"
                      size={24}
                      color={mainColors['primary']}
                    />
                  </div>
                </td>

              </tr>
            ))}
          </tbody>
        ) : null}
      </Table>
      {!renderedItems?.length && (
        <div className="d-flex text-fs-body-md text-t-body-color justify-content-center">
          No abi found according to search results.
        </div>
      )}


      {pages?.length > 1 ? (
        <Pagination className="d-flex justify-content-center">
          <Pagination.First onClick={() => firstPage()} />
          <Pagination.Prev onClick={() => prevPage()} />
          {renderedPages.map((page, index) => {
            return (
              <Pagination.Item
                key={index}
                active={page === currentPage}
                onClick={() => changePage(page)}
              >
                {page}
              </Pagination.Item>
            );
          })}
          <Pagination.Next onClick={() => nextPage()} />
          <Pagination.Last onClick={() => lastPage()} />
        </Pagination>
      ) : null}
      <div className="text-end">
        <Button className='mt-2' onClick={handleOpenCreateModal}>
          Create Contract
        </Button>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">ABI File</label>
            <input type="file" onChange={handleAbiChange} className="form-control" />
          </div>
          <div className="mb-3">
            <label className="form-label">Code File</label>
            <input type="file" onChange={handleCodeChange} className="form-control" />
          </div>
          <div className="mb-3">
            <label className="form-label">Byte Code File</label>
            <input type="file" onChange={handleByteCodeChange} className="form-control" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>Close</Button>
          <Button variant="primary" onClick={handleUpload}>Upload</Button>
        </Modal.Footer>
      </Modal>

      {/* Create Modal */}
      <Modal show={showCreateModal} onHide={handleCloseCreateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Contract</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">ABI File</label>
            <input type="file" onChange={handleCreateAbiChange} className="form-control" />
          </div>
          <div className="mb-3">
            <label className="form-label">Code File</label>
            <input type="file" onChange={handleCreateCodeChange} className="form-control" />
          </div>
          <div className="mb-3">
            <label className="form-label">Byte Code File</label>
            <input type="file" onChange={handleCreateByteCodeChange} className="form-control" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseCreateModal}>Close</Button>
          <Button variant="primary" onClick={handleCreateUpload}>Upload</Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

const mapStateToProps = (state) => {
  return {
    abiHistory: state.abiReducer.abiHistory,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    abiHistoryRequest: (payload) => {
      dispatch(abiRequestAction(payload));
    },
    createContractRequest: (payload) => {
      dispatch(createContractActionRequest(payload));
    },
    updateContractRequest: (payload) => {
      dispatch(updateContractActionRequest(payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MercurXContractTable);