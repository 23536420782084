export const ABI_REQUEST = 'ABI_REQUEST';
export const ABI_DATA = 'ABI_DATA';
export const ABI_ERROR = 'ABI_ERROR';

export const CREATE_CONTRACT_REQUEST = 'CREATE_CONTRACT_REQUEST';
export const CREATE_CONTRACT_DATA = 'CREATE_CONTRACT_DATA';
export const CREATE_CONTRACT_ERROR = 'CREATE_CONTRACT_ERROR';
export const CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS';

export const UPDATE_CONTRACT_REQUEST = 'UPDATE_CONTRACT_REQUEST';
export const UPDATE_CONTRACT_DATA = 'UPDATE_CONTRACT_DATA';
export const UPDATE_CONTRACT_ERROR = 'UPDATE_CONTRACT_ERROR';
export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS';

