/* eslint-disable max-len */
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import './MercurxContractPresaleDetailInputs.scss'
import { Button, Col, Form, Row, Card, Dropdown } from 'react-bootstrap';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { abiRequestAction } from '../../store/abi/abiActions';
import wallet from '../../helpers/wallet';
import Swal from 'sweetalert2';
import { mainColors } from '../../helpers/colors';
import { getBNB } from '../../store/bnb/bnbActions';
import { setAlertAction } from '../../store/alert/alertActions';
import { setLoadingFullAction } from '../../store/loading/loadingActions';
import { transactionRequest } from '../../store/bo_transaction/bo_transactionActions';
const MercurxContractPresaleDetailInputs = ({ ...props }) => {
  const [selectedUnit, setSelectedUnit] = useState('WEI');
  const [contractFunctions, setContractFunctions] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState('');
  const [functionParams, setFunctionParams] = useState([]);
  const [paramValues, setParamValues] = useState([]);
  const [results, setResults] = useState(null);
  const [resultsInput, setResultsInput] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [validationAddressError, setValidationAddressError] = useState(null);

  const validateParams = () => {
    const ethAddressRegex = /^0x[a-fA-F0-9]{40}$/;
    for (let i = 0; i < paramValues.length; i++) {
      const paramName = contractFunctions[i]?.name;
      const value = paramValues[i];

      if ((paramName === 'address' || paramName === 'spender'
        || paramName === 'owner' || paramName === 'newOwner'
        || paramName === 'from' || paramName === 'to') && !ethAddressRegex.test(value)) {
        setValidationAddressError(`The input for ${paramName} must be a valid Ethereum address.`);
        return false;
      }
    }
    setValidationAddressError(null);
    return true;
  };

  const handleUnitChange = (unit) => setSelectedUnit(unit);

  const {
    project,
    abiHistoryRequest,
    abiHistory,
    getBNBRequest,
    bnbData,
    setAlert,
    setLoadingFull,
    transactionRequest,
    isLoading,
    accounts
  } = props;

  const handleAbi = () => {
    abiHistoryRequest();
    getBNBRequest();
  };

  useEffect(() => {
    handleAbi();
  }, []);
  useEffect(() => {
    if (abiHistory?.[0]?.['MERX_presale_abi']) {
      const sortedFunctions = [...abiHistory[0]['MERX_presale_abi']].sort((a, b) => {
        if (a.stateMutability === 'view' && b.stateMutability !== 'view') return 1;
        if (a.stateMutability !== 'view' && b.stateMutability === 'view') return -1;
        return 0;
      });
      abiHistory[0]['MERX_presale_abi'] = sortedFunctions;
    }
  }, [abiHistory]);
  useEffect(() => {
    if (contractFunctions.length === 0 && selectedFunction) {
      runContract();
    }
  }, [contractFunctions, selectedFunction, paramValues]);

  const runContract = async () => {
    if (!validateParams()) {
      return;
    }
    let transactionDetails;
    setLoadingFull({ isLoading: true, loadingText: 'Waiting...' });
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      const web3 = new Web3(window.ethereum);
      const project_id = 1;
      const project_name = "MERCURX_PRESALE";
      const transaction_time = new Date();
      const user_public_address = signerAddress;
      const action = abiHistory?.[0]?.['MERX_presale_abi']?.find(fn => fn.name === selectedFunction).name;
      const token_address = '0xD840FA31Ee4271112171C2313e7d1928e0CB3D19';
      
      await wallet.controlAndSwitchOrAddNetwork();
      await window.ethereum.enable();

      const mercurx_contract = new web3.eth.Contract(
        abiHistory?.[0]?.['MERX_presale_abi'],
        '0xA3dEE80bf8b816049a5226b4689488f3ff369C66'
      );
      const contractMethod = mercurx_contract.methods[selectedFunction];
      const data = contractMethod(...paramValues).encodeABI();
      const selectedContract = abiHistory?.[0]?.['MERX_presale_abi']?.find(fn => fn.name === selectedFunction);

      setResults(null);
      setResultsInput(null);

      if (selectedContract.stateMutability === 'view') {
        const result = await contractMethod(...paramValues).call();
        const formattedResult = typeof result === 'boolean' ? result.toString() : result;
        if (contractFunctions.length === 0) {
          setResults(formattedResult);
          console.log('Call result:', formattedResult);
        } else {
          setResultsInput(formattedResult);
          console.log('Call input result:', formattedResult);
        }
      } else {
        const transaction = await contractMethod(...paramValues).send({
          from: signerAddress,
          to: '0xA3dEE80bf8b816049a5226b4689488f3ff369C66',
          data: web3.eth.abi.encodeFunctionSignature('whitdrawETH()'),
        });

        transactionDetails = transaction;
        setTransactions((prev) => [...prev, transaction]);
        const transaction_hash = transaction.transactionHash;
        const transaction_status = transaction.status;
        const token_count = paramValues.length > 1 ? paramValues[paramValues.length - 1] : 0;
        // TODO : 0lar null olacak.
        
        const payload2 = {
          action,
          project_id,
          project_name,
          token_count,
          user_public_address,
          token_address,
          transaction_hash,
          transaction_time,
          transaction_status,
        };
        console.log(payload2)
        transactionRequest(payload2);
        Swal.fire({
          icon: 'success',
          iconColor: mainColors.primary,
          text: 'Transaction succeeded',
          confirmButtonColor: mainColors.primary,
          html: `<a href=https://testnet.bscscan.com/tx/${transactionDetails?.transactionHash}
            target='_blank'> Check Detail Transaction !</a>`,
        });
      }
    } catch (err) {
      console.error('Error during contract execution:', err);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      const transaction_hash = '';
      const transaction_status = false;
      const transaction_time = new Date();
      const project_id = 1;
      const project_name = "MERCURX_PRESALE";
      const token_count = paramValues.length > 1 ? paramValues[paramValues.length - 1] : 0;
      // TODO : 0lar null olacak.
      const user_public_address = signerAddress;
      const action = abiHistory?.[0]?.['MERX_presale_abi']?.find(fn => fn.name === selectedFunction).name;
      const token_address = '0xD840FA31Ee4271112171C2313e7d1928e0CB3D19';
      const payload2 = {
        action,
        project_id,
        project_name,
        token_count,
        user_public_address,
        token_address,
        transaction_hash,
        transaction_time,
        transaction_status,
      };
      console.log(payload2)
      transactionRequest(payload2);
    } finally {
      setLoadingFull({ isLoading: false });
    }
  };

  const handleFunctionChange = (selected) => {
    setSelectedFunction(selected);
    const func = abiHistory?.[0]?.['MERX_presale_abi']?.find(fn => fn.name === selected);
    setContractFunctions(func?.inputs || []);

    setResults(null);
    setResultsInput(null);
    setParamValues([]);
    setTransactions([]);
  };



  const handleParamChange = (index, value) => {
    const newValues = [...paramValues];
    newValues[index] = value;
    setParamValues(newValues);
  };

  useEffect(() => {
    const selectElement = document.getElementById('selectFunction');
    if (selectElement) {
      for (let i = 0; i < selectElement.options.length; i++) {
        const option = selectElement.options[i];
        const selectedAbi = abiHistory?.[0]?.['MERX_presale_abi']?.find
          (fn => fn.name === option.value);
        if (selectedAbi) {
          option.style.backgroundColor = selectedAbi.stateMutability
            === 'view' ? 'green' : 'orange';
          option.style.color = 'white';
        }
      }
    }
  }, [abiHistory]);

  const getDropdownColor = () => {
    if (!selectedFunction || selectedFunction === 'Select a function') {
      return 'grey';
    }

    const func = abiHistory?.[0]?.['MERX_presale_abi']?.find(fn => fn.name === selectedFunction);
    if (func && func.stateMutability === 'view') {
      return mainColors['primary'];
    }

    return 'orange';
  };
  return (
    <div className="mercurx-contract-detail-inputs m-4">
      <Row>
        <Col md={4}>
          <Form.Group controlId="selectFunction">
            <Form.Label className='mt-2'>Select Function</Form.Label>
            <Dropdown>
              <Dropdown.Toggle
                variant="primary"
                id="dropdown-basic"
                disabled={!accounts?.[0]}
                style={{
                  width: '205px',
                  backgroundColor: getDropdownColor(),
                  borderColor: !selectedFunction || selectedFunction === 'Select a function'
                    ? 'grey'
                    : selectedFunction && abiHistory?.[0]?.['MERX_presale_abi']?.find(fn => fn.name === selectedFunction)?.stateMutability === 'view'
                      ? mainColors['primary']
                      : 'orange'
                }}
              >
                {selectedFunction || 'Select a function'}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {abiHistory?.[0]?.['MERX_presale_abi']?.map(fn => (
                  <Dropdown.Item
                    key={fn.name}
                    onClick={() => handleFunctionChange(fn.name)}
                    style={{
                      backgroundColor: fn.stateMutability === 'view' ? mainColors['primary'] : 'orange',
                      color: 'white',
                      borderColor: selectedFunction && abiHistory?.[0]?.['MERX_presale_abi']?.find(fn => fn.name === selectedFunction)?.stateMutability === 'view'
                        ? mainColors['primary']
                        : 'orange'
                    }}
                  >
                    {fn.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Col>
        <Col className='mt-2' md={8}>
          {contractFunctions.length > 0 ? (
            <>
              {contractFunctions.map((param, index) => (
                <Form.Group key={param.name} controlId={`param-${index}`}>
                  <Form.Label className='mt-2'>{param.name}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={`Enter ${param.name}`}
                    onChange={(e) => handleParamChange(index, e.target.value)}
                    isInvalid={validationAddressError && selectedFunction && validationAddressError.includes(param.name)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationAddressError && selectedFunction && validationAddressError.includes(param.name) ? validationAddressError : ''}
                  </Form.Control.Feedback>
                </Form.Group>
              ))}

              <Button
                variant="primary"
                className="mt-2"
                onClick={() => {
                  if (!validateParams()) {
                    Swal.fire({
                      icon: 'error',
                      iconColor: mainColors['error'],
                      text: 'Please correct the input errors before executing.',
                      confirmButtonColor: mainColors.primary,
                    });
                  } else {
                    runContract();
                  }
                }}
                style={{
                  backgroundColor: selectedFunction !== 'Select a function' ? getDropdownColor() : 'grey',
                  borderColor: selectedFunction && selectedFunction !== 'Select a function' ? getDropdownColor() : 'grey'
                }}
              >
                Execute
              </Button>
            </>
          ) : null}

          {resultsInput && (
            <Card className='mt-4'>
              <Card.Header>Input Results</Card.Header>
              <Card.Body>
                <pre>{JSON.stringify(resultsInput, null, 2)}</pre>
              </Card.Body>
            </Card>
          )}

          {results && (
            <Card className='mt-4'>
              <Card.Header>Results</Card.Header>
              <Card.Body>
                <pre>{JSON.stringify(results, null, 2)}</pre>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          {transactions.length > 0 && (
            <div className="text-fs-head-sm">Transaction Details:</div>
          )}
          {transactions.map((txn, index) => (
            <Card key={index} className="mb-2 transaction-card">
              <Card.Body>
                <pre>{JSON.stringify(txn, null, 2)}</pre>
              </Card.Body>
            </Card>
          ))}
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    abiHistory: state.abiReducer.abiHistory,
    bnbData: state.bnbReducer.bnb,
    isLoading: state.loadingReducer.isLoading,
    accounts: state.walletReducer.accounts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    abiHistoryRequest: (payload) => {
      dispatch(abiRequestAction(payload));
    },
    getBNBRequest: (payload) => {
      dispatch(getBNB(payload));
    },
    setAlert: (payload) => {
      dispatch(setAlertAction(payload));
    },
    setLoadingFull: (payload) => {
      dispatch(setLoadingFullAction(payload));
    },
    transactionRequest: (creds) => {
      dispatch(transactionRequest(creds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MercurxContractPresaleDetailInputs);
