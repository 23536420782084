/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './MercurXContractDetailInputs.scss';
import { Button, Col, Form, Row, Card, Dropdown } from 'react-bootstrap';
import Web3 from 'web3';
import { ethers } from 'ethers';
import Swal from 'sweetalert2';
import { mainColors } from '../../helpers/colors';
import wallet from '../../helpers/wallet';
import { abiRequestAction } from '../../store/abi/abiActions';
import { getBNB } from '../../store/bnb/bnbActions';
import { setAlertAction } from '../../store/alert/alertActions';
import { setLoadingFullAction } from '../../store/loading/loadingActions';
import { transactionRequest } from '../../store/bo_transaction/bo_transactionActions';
import { useLocation } from 'react-router-dom';

const MercurXContractDetailInputs = ({ ...props }) => {
  const location = useLocation();
  const { itemName } = location.state || {};
  const [selectedUnit, setSelectedUnit] = useState('WEI');
  const [contractFunctions, setContractFunctions] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState('');
  const [functionParams, setFunctionParams] = useState([]);
  const [paramValues, setParamValues] = useState([]);
  const [results, setResults] = useState(null);
  const [resultsInput, setResultsInput] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [validationAddressError, setValidationAddressError] = useState(null);

  const validateParams = () => {
    const ethAddressRegex = /^0x[a-fA-F0-9]{40}$/;
    for (let i = 0; i < paramValues.length; i++) {
      const paramName = contractFunctions[i]?.name;
      const value = paramValues[i];

      if ((paramName === 'address' || paramName === 'spender'
        || paramName === 'owner' || paramName === 'account'
        || paramName === 'from' || paramName === 'to') && !ethAddressRegex.test(value)) {
        setValidationAddressError(`The input for ${paramName} must be a valid Ethereum address.`);
        return false;
      }
    }
    setValidationAddressError(null);
    return true;
  };

  const handleUnitChange = (unit) => setSelectedUnit(unit);

  const {
    projects,
    abiHistoryRequest,
    abiHistory,
    getBNBRequest,
    bnbData,
    setAlert,
    setLoadingFull,
    transactionRequest,
    isLoading,
    accounts
  } = props;

  const handleAbi = () => {
    abiHistoryRequest();
    getBNBRequest();
  };

  useEffect(() => {
    handleAbi();
  }, []);

  useEffect(() => {
    if (contractFunctions.length === 0 && selectedFunction) {
      if (itemName === "MERX_abi") {
        runContract();
      } else {
        runContractOthers();
      }
    }
  }, [contractFunctions, selectedFunction, paramValues, itemName]);

  const runContract = async () => {
    if (!validateParams()) {
      return;
    }
    let transactionDetails;
    setLoadingFull({ isLoading: true, loadingText: 'Waiting...' });
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      const web3 = new Web3(window.ethereum);
      const project_id = 1;
      const project_name = "MERCURX";
      const transaction_time = new Date();
      const user_public_address = signerAddress;
      const action = abiHistory?.[itemName]?.data?.find(fn => fn.name === selectedFunction).name;
      const token_address = projects?.[1].token.address;
      await wallet.controlAndSwitchOrAddNetwork();
      await window.ethereum.enable();

      const mercurx_contract = new web3.eth.Contract(
        abiHistory?.[itemName]?.data,
        projects?.[1].token.address
      );
      const contractMethod = mercurx_contract.methods[selectedFunction];
      const data = contractMethod(...paramValues).encodeABI();
      const selectedContract = abiHistory?.[itemName]?.data?.find(fn => fn.name === selectedFunction);

      setResults(null);
      setResultsInput(null);

      if (selectedContract.stateMutability === 'view') {
        const result = await contractMethod(...paramValues).call();
        if (contractFunctions.length === 0) {
          setResults(result);
          console.log('Call result:', result);
        } else {
          setResultsInput(result);
          console.log('Call input result:', result);
        }
      } else {
        const transaction = await contractMethod(...paramValues).send({
          from: signerAddress,
          to: projects?.[1].token.address,
          data: web3.eth.abi.encodeFunctionSignature('whitdrawETH()'),
        });
        transactionDetails = transaction;
        setTransactions((prev) => [...prev, transaction]);
        const transaction_hash = transaction.transactionHash;
        const transaction_status = transaction.status;
        const token_count = paramValues.length > 1 ? paramValues[paramValues.length - 1] : 0;
        // TODO : 0lar null olacak.
        const payload2 = {
          action,
          project_id,
          project_name,
          token_count,
          user_public_address,
          token_address,
          transaction_hash,
          transaction_time,
          transaction_status,
        };
        console.log(payload2)
        transactionRequest(payload2);

        Swal.fire({
          icon: 'success',
          iconColor: mainColors.primary,
          text: 'Transaction succeeded',
          confirmButtonColor: mainColors.primary,
          html: `<a href=https://testnet.bscscan.com/tx/${transactionDetails?.transactionHash}
            target='_blank'> Check Detail Transaction !</a>`,
        });
      }
    } catch (err) {
      console.error('Error during contract execution:', err);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      const transaction_hash = '';
      const transaction_status = false;
      const transaction_time = new Date();
      const project_id = 1;
      const project_name = "MERCURX";
      const token_count = paramValues.length > 1 ? paramValues[paramValues.length - 1] : 0;
      // TODO : 0lar null olacak.
      const user_public_address = signerAddress;
      const action = abiHistory?.[itemName]?.data?.find(fn => fn.name === selectedFunction).name;
      const token_address = projects?.[1].token.address;
      const payload2 = {
        action,
        project_id,
        project_name,
        token_count,
        user_public_address,
        token_address,
        transaction_hash,
        transaction_time,
        transaction_status,
      };
      console.log(payload2)
      transactionRequest(payload2);
    } finally {
      setLoadingFull({ isLoading: false });
    }
  };

  const runContractOthers = async () => {
    if (!validateParams()) {
      return;
    }
    let transactionDetails;
    setLoadingFull({ isLoading: true, loadingText: 'Waiting...' });
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      const web3 = new Web3(window.ethereum);
      const project_id = 1;
      const project_name = itemName === "MERX_presale_abi" ? "MERCURX_PRESALE" : "MERCURX_STAKE";
      const transaction_time = new Date();
      const user_public_address = signerAddress;
      const action = abiHistory?.[itemName]?.data?.find(fn => fn.name === selectedFunction).name;
      const token_address = projects?.[1].token.address;

      await wallet.controlAndSwitchOrAddNetwork();
      await window.ethereum.enable();

      const mercurx_contract = new web3.eth.Contract(
        abiHistory?.[itemName]?.data,
        itemName === "MERX_presale_abi" ? projects?.[1].token.presale_contract.contract_address :
          projects?.[1].token.staking_contract.contract_address
      );
      const contractMethod = mercurx_contract.methods[selectedFunction];
      const data = contractMethod(...paramValues).encodeABI();
      const selectedContract = abiHistory?.[itemName]?.data?.find(fn => fn.name === selectedFunction);

      setResults(null);
      setResultsInput(null);

      if (selectedContract.stateMutability === 'view') {
        const result = await contractMethod(...paramValues).call();
        const formattedResult = typeof result === 'boolean' ? result.toString() : result;
        if (contractFunctions.length === 0) {
          setResults(formattedResult);
          console.log('Call result:', formattedResult);
        } else {
          setResultsInput(formattedResult);
          console.log('Call input result:', formattedResult);
        }
      } else {
        const transaction = await contractMethod(...paramValues).send({
          from: signerAddress,
          to: itemName === "MERX_presale_abi" ? projects?.[1].token.presale_contract.contract_address :
            projects?.[1].token.staking_contract.contract_address,
          data: web3.eth.abi.encodeFunctionSignature('whitdrawETH()'),
        });

        transactionDetails = transaction;
        setTransactions((prev) => [...prev, transaction]);
        const transaction_hash = transaction.transactionHash;
        const transaction_status = transaction.status;
        const token_count = paramValues.length > 1 ? paramValues[paramValues.length - 1] : 0;
        // TODO : 0lar null olacak.

        const payload2 = {
          action,
          project_id,
          project_name,
          token_count,
          user_public_address,
          token_address,
          transaction_hash,
          transaction_time,
          transaction_status,
        };
        console.log(payload2)
        transactionRequest(payload2);
        Swal.fire({
          icon: 'success',
          iconColor: mainColors.primary,
          text: 'Transaction succeeded',
          confirmButtonColor: mainColors.primary,
          html: `<a href=https://testnet.bscscan.com/tx/${transactionDetails?.transactionHash}
            target='_blank'> Check Detail Transaction !</a>`,
        });
      }
    } catch (err) {
      console.error('Error during contract execution:', err);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      const transaction_hash = '';
      const transaction_status = false;
      const transaction_time = new Date();
      const project_id = 1;
      const project_name = itemName === "MERX_presale_abi" ? "MERCURX_PRESALE" : "MERCURX_STAKE";
      const token_count = paramValues.length > 1 ? paramValues[paramValues.length - 1] : 0;
      // TODO : 0lar null olacak.
      const user_public_address = signerAddress;
      const action = abiHistory?.[itemName]?.data?.find(fn => fn.name === selectedFunction).name;
      const token_address = projects?.[1].token.address;
      const payload2 = {
        action,
        project_id,
        project_name,
        token_count,
        user_public_address,
        token_address,
        transaction_hash,
        transaction_time,
        transaction_status,
      };
      console.log(payload2)
      transactionRequest(payload2);
    } finally {
      setLoadingFull({ isLoading: false });
    }
  };

  const handleParamChange = (index, value) => {
    const newValues = [...paramValues];
    newValues[index] = value;
    setParamValues(newValues);
  };

  useEffect(() => {
    const selectElement = document.getElementById('selectFunction');
    if (selectElement) {
      for (let i = 0; i < selectElement.options.length; i++) {
        const option = selectElement.options[i];
        const selectedAbi = abiHistory?.[itemName]?.data?.find
          (fn => fn.name === option.value);
        if (selectedAbi) {
          option.style.backgroundColor = selectedAbi.stateMutability
            === 'view' ? 'green' : 'orange';
          option.style.color = 'white';
        }
      }
    }
  }, [abiHistory]);

  const getDropdownColor = () => {
    if (!selectedFunction || selectedFunction === 'Select a function') {
      return 'grey';
    }

    const func = abiHistory?.[itemName]?.data?.find(fn => fn.name === selectedFunction);
    if (func && func.stateMutability === 'view') {
      return mainColors['primary'];
    }

    return 'orange';
  };
  const handleFunctionChange = (selected) => {
    setSelectedFunction(selected);
    const func = abiHistory?.[itemName]?.data?.find(fn => fn.name === selected);
    setContractFunctions(func?.inputs || []);

    setResults(null);
    setResultsInput(null);
    setParamValues([]);
    setTransactions([]);
  };

  const handleExecuteContract = () => {
    if (!validateParams()) {
      Swal.fire({
        icon: 'error',
        iconColor: mainColors['error'],
        text: 'Please correct the input errors before executing.',
        confirmButtonColor: mainColors.primary,
      });
      return;
    }

    if (itemName === "MERX_abi") {
      runContract();
    } else {
      runContractOthers();
    }
  };

  return (
    <div className="mercurx-contract-detail-inputs m-4">
      <Row>
        <Col md={4}>
          <Form.Group controlId="selectFunction">
            <Form.Label className='mt-2'>Select Function</Form.Label>
            <Dropdown>
              <Dropdown.Toggle
                variant="primary"
                id="dropdown-basic"
                disabled={!accounts?.[0]}
                style={{
                  width: '205px',
                  backgroundColor: getDropdownColor(),
                  borderColor: !selectedFunction || selectedFunction === 'Select a function'
                    ? 'grey'
                    : selectedFunction && abiHistory?.[itemName]?.data?.find(fn => fn.name === selectedFunction)?.stateMutability === 'view'
                      ? mainColors['primary']
                      : 'orange'
                }}
              >
                {selectedFunction || 'Select a function'}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {abiHistory?.[itemName]?.data?.map(fn => (
                  <Dropdown.Item
                    key={fn.name}
                    onClick={() => handleFunctionChange(fn.name)}
                    style={{
                      backgroundColor: fn.stateMutability === 'view' ? mainColors['primary'] : 'orange',
                      color: 'white',
                      borderColor: selectedFunction && abiHistory?.[itemName]?.data?.find(fn => fn.name === selectedFunction)?.stateMutability === 'view'
                        ? mainColors['primary']
                        : 'orange'
                    }}
                  >
                    {fn.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Col>
        <Col className='mt-2' md={8}>
          {contractFunctions.length > 0 ? (
            <>
              {contractFunctions.map((param, index) => (
                <Form.Group key={param.name} controlId={`param-${index}`}>
                  <Form.Label className='mt-2'>{param.name}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={`Enter ${param.name}`}
                    onChange={(e) => handleParamChange(index, e.target.value)}
                    isInvalid={validationAddressError && selectedFunction && validationAddressError.includes(param.name)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationAddressError && selectedFunction && validationAddressError.includes(param.name) ? validationAddressError : ''}
                  </Form.Control.Feedback>
                </Form.Group>
              ))}

              <Button
                variant="primary"
                className="mt-2"
                onClick={handleExecuteContract}
                style={{
                  backgroundColor: selectedFunction !== 'Select a function' ? getDropdownColor() : 'grey',
                  borderColor: selectedFunction && selectedFunction !== 'Select a function' ? getDropdownColor() : 'grey'
                }}
              >
                Execute
              </Button>
            </>
          ) : null}

          {resultsInput && (
            <Card className='mt-4'>
              <Card.Header>Input Results</Card.Header>
              <Card.Body>
                <pre>{JSON.stringify(resultsInput, null, 2)}</pre>
              </Card.Body>
            </Card>
          )}

          {results && (
            <Card className='mt-4'>
              <Card.Header>Results</Card.Header>
              <Card.Body>
                <pre>{JSON.stringify(results, null, 2)}</pre>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          {transactions.length > 0 && (
            <div className="text-fs-head-sm">Transaction Details:</div>
          )}
          {transactions.map((txn, index) => (
            <Card key={index} className="mb-2 transaction-card">
              <Card.Body>
                <pre>{JSON.stringify(txn, null, 2)}</pre>
              </Card.Body>
            </Card>
          ))}
        </Col>
      </Row>
    </div>
  );

};

const mapStateToProps = (state) => ({
  abiHistory: state.abiReducer.abiHistory,
  bnbData: state.bnbReducer.bnb,
  isLoading: state.loadingReducer.isLoading,
  projects: state.projectReducer.projects,
  accounts: state.walletReducer.accounts,
});

const mapDispatchToProps = (dispatch) => ({
  setLoadingFull: (payload) => dispatch(setLoadingFullAction(payload)),
  abiHistoryRequest: (payload) => dispatch(abiRequestAction(payload)),
  getBNBRequest: (payload) => dispatch(getBNB(payload)),
  setAlert: (payload) => dispatch(setAlertAction(payload)),
  transactionRequest: (creds) => dispatch(transactionRequest(creds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MercurXContractDetailInputs);
