import {
  BACKOFFICEUSERS_SORT_KEYS,
  BACKOFFICEUSERS_SORT_TYPES,
  backofficeUsersQuickFilterConstants,
  sortKeys,
} from '../../pages/BackofficeUsers/backofficeUsersConstants';
import * as types from './backofficeUserActionTypes';

const initialState = {
  backofficeUsers: null,
  filteredBackofficeUsers: null,
  createdBackofficeUser: null,
  updatedBackofficeUser: null,
  backofficeUsersSortData: {
    sortType: BACKOFFICEUSERS_SORT_TYPES.ASCENDING,
    sortKey: BACKOFFICEUSERS_SORT_KEYS.FULL_NAME,
  },
  quickFilter: 1,
  error: {
    type: null,
    data: null,
  },
};

export const backofficeUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BACKOFFICEUSERS_DATA:
      let backofficeUsers = action.payload.result;
      return {
        ...state,
        backofficeUsers: backofficeUsers ? [...backofficeUsers] : null,
      };
    case types.GET_BACKOFFICEUSERS_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_BACKOFFICEUSERS_ERROR,
          data: action.payload,
        },
      };
      case types.CREATE_BACKOFFICEUSER_DATA:
      return {
        ...state,
        createdBackofficeUser: action.payload,
      };
    case types.CREATE_BACKOFFICEUSER_ERROR:
      return {
        ...state,
        error: {
          type: types.CREATE_BACKOFFICEUSER_ERROR,
          data: action.payload,
        },
      };
    case types.UPDATE_BACKOFFICEUSER_DATA:
      return {
        ...state,
        updatedBackofficeUser: action.payload,
      };
    case types.UPDATE_BACKOFFICEUSER_ERROR:
      return {
        ...state,
        error: {
          type: types.UPDATE_BACKOFFICEUSER_ERROR,
          data: action.payload,
        },
      };
    case types.FILTER_BACKOFFICEUSERS:
      function filterBackofficeUsers(filterInput) {
        console.log(filterInput)
        const filteredBackofficeUsers = [];

        let tempBackofficeUsersForFilter = state.backofficeUsers;
        console.log('Filtered BackofficeUsers:', tempBackofficeUsersForFilter);

        if (state.quickFilter === backofficeUsersQuickFilterConstants.TRUE) {
          tempBackofficeUsersForFilter = tempBackofficeUsersForFilter.filter((t) =>
           t.is_active === "True");
        } else if (state.quickFilter === backofficeUsersQuickFilterConstants.FALSE) {
          tempBackofficeUsersForFilter = tempBackofficeUsersForFilter.filter((t) =>
           t.is_active === "False");
        }
        else if (state.quickFilter === backofficeUsersQuickFilterConstants.WAIT) {
          tempBackofficeUsersForFilter = tempBackofficeUsersForFilter.filter((t) =>
           t.is_active !== "True" && t.is_active!=="False");
        }
      
        

        if (tempBackofficeUsersForFilter) {
          for (const fBackofficeUsers of tempBackofficeUsersForFilter) {
            if (
              fBackofficeUsers?.full_name?.toString().toLowerCase().includes(filterInput) ||
              fBackofficeUsers?.email?.toString().toLowerCase().includes(filterInput)
            ) {
              filteredBackofficeUsers.push(fBackofficeUsers);
            }
          }

          return filteredBackofficeUsers;
        }
      }

      let filteredBackofficeUsers = null;
    
      filteredBackofficeUsers = filterBackofficeUsers(action?.payload?.toString().toLowerCase());

      return {
        ...state,
        filteredBackofficeUsers: filteredBackofficeUsers ? [...filteredBackofficeUsers] : null,
      };
    case types.SET_BACKOFFICEUSERS_SORT_DATA:
      return {
        ...state,
        backofficeUsersSortData: { ...action.payload },
      };
      case types.SORT_BACKOFFICEUSERS:
        const selectedKey = sortKeys[state.backofficeUsersSortData.sortKey].key;
        const tempBackofficeUserForSorting = state.filteredBackofficeUsers?.length
          ? state.filteredBackofficeUsers
          : state.backofficeUsers;
          console.log("Temp BackofficeUser For Sorting:", tempBackofficeUserForSorting);
  
          const sortedBackofficeUsers = tempBackofficeUserForSorting?.sort((a, b) => {
            const isAActive = a.is_active === "true";
            const isBActive = b.is_active === "true";
            const fullNameComparison = a[selectedKey]
              ?.toString()
              .toLowerCase()
              .localeCompare(b[selectedKey]?.toString().toLowerCase());
          
              const fullNameComparisonDesc = b[selectedKey]
              ?.toString()
              .toLowerCase()
              .localeCompare(a[selectedKey]?.toString().toLowerCase());
          
            if (state.backofficeUsersSortData.sortType === BACKOFFICEUSERS_SORT_TYPES.ASCENDING) {

              if (selectedKey === 'full_name') {
                return fullNameComparison;
              } else {
                return (
                  
                  a.email
                    ?.toString()
                    .toLowerCase()
                    .localeCompare(b.email?.toString().toLowerCase())
                );
              }
            } else {
              const isActiveComparison = isBActive - isAActive;
              if (selectedKey === 'full_name') {
                return  fullNameComparisonDesc;
              } else {
                return (

                  b.email
                    ?.toString()
                    .toLowerCase()
                    .localeCompare(a.email?.toString().toLowerCase())
                );
              }
            }
          });
          
      if (state.filteredBackofficeUsers?.length) {
        return {
          ...state,
          filteredBackofficeUsers: sortedBackofficeUsers ? [...sortedBackofficeUsers] : null,
        };
      }
      return {
        ...state,
        backofficeUsers: sortedBackofficeUsers ? [...sortedBackofficeUsers] : state.backofficeUsers,
      };
    case types.UPDATE_QUICK_FILTER:
      return {
        ...state,
        quickFilter: action?.payload,
      };
    default:
      return state;
  }
};
