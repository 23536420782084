import {
  TRANSACTIONS_SORT_KEYS,
  TRANSACTIONS_SORT_TYPES,
  transactionsQuickFilterConstants,
  sortKeys,
} from '../../pages/Transactions/transactionsConstants';
import * as types from './transactionActionTypes';

const initialState = {
  transactions: null,
  filteredTransactions: null,
  transactionsSortData: {
    sortType: TRANSACTIONS_SORT_TYPES.ASCENDING,
    sortKey: TRANSACTIONS_SORT_KEYS.PROJECT_NAME,
  },
  quickFilter: 1,
  error: {
    type: null,
    data: null,
  },
};

export const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TRANSACTIONS_DATA:
      let transactions = action.payload.result
      return {
        ...state,
        transactions: transactions ? [...transactions] : null,
      };

    case types.GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_TRANSACTIONS_ERROR,
          data: action.payload,
        },
      };
    case types.FILTER_TRANSACTIONS:
      function filterTransactions(filterInput) {
        console.log(filterInput)
        const filteredTransactions = [];


        let tempTransactionsForFilter = state.transactions;
        // console.log('Filtered Transactions:', tempTransactionsForFilter);

        if (state.quickFilter === transactionsQuickFilterConstants.TRUE) {
          tempTransactionsForFilter = tempTransactionsForFilter.filter(
            (t) => t.transaction_status === true);
        } else if (state.quickFilter === transactionsQuickFilterConstants.FALSE) {
          tempTransactionsForFilter = tempTransactionsForFilter.filter(
            (t) => t.transaction_status === false);
        }


        if (tempTransactionsForFilter) {
          for (const fTransactions of tempTransactionsForFilter) {
            if (
              fTransactions?.transaction_hash?.toString().toLowerCase().includes(filterInput) ||
              fTransactions?.project_name?.toString().toLowerCase().includes(filterInput) ||
              fTransactions?.token_address?.toString().toLowerCase().includes(filterInput) ||
              fTransactions?.user_public_address?.toString().toLowerCase().includes(filterInput)
            ) {
              filteredTransactions.push(fTransactions);
            }
          }

          return filteredTransactions;
        }
      }

      let filteredTransactions = null;
      filteredTransactions = filterTransactions(action?.payload?.toString().toLowerCase());

      return {
        ...state,
        filteredTransactions: filteredTransactions ? [...filteredTransactions] : null,
      };
    case types.SET_TRANSACTIONS_SORT_DATA:
      return {
        ...state,
        transactionsSortData: { ...action.payload },
      };
    case types.SORT_TRANSACTIONS:
      const selectedTransactionKey = sortKeys[state.transactionsSortData.sortKey].key;
      const tempTransactionsForSorting = state.filteredTransactions?.length
        ? state.filteredTransactions
        : state.transactions;
      console.log("Temp Transactions For Sorting:", tempTransactionsForSorting);

      const sortedTransactions = tempTransactionsForSorting?.sort((a, b) => {
        if (state.transactionsSortData.sortType === TRANSACTIONS_SORT_TYPES.ASCENDING) {
          return (
            (selectedTransactionKey === 'date' ?
              new Date(a.transaction_time) - new Date(b.transaction_time) :
              a[selectedTransactionKey]
                ?.toString()
                .toLowerCase()
                .localeCompare(b[selectedTransactionKey]?.toString().toLowerCase())
            )
          );
        } else {
          return (
            (selectedTransactionKey === 'date' ?
              new Date(b.transaction_time) - new Date(a.transaction_time) :
              b[selectedTransactionKey]
                ?.toString()
                .toLowerCase()
                .localeCompare(a[selectedTransactionKey]?.toString().toLowerCase())
            )
          );
        }
      });

      if (state.filteredTransactions?.length) {
        return {
          ...state,
          filteredTransactions: sortedTransactions ? [...sortedTransactions] : null,
        };
      }
      return {
        ...state,
        transactions: sortedTransactions ? [...sortedTransactions] : state.transactions,
      };

    case types.UPDATE_QUICK_FILTER:
      return {
        ...state,
        quickFilter: action?.payload,
      };
    default:
      return state;
  }
};

