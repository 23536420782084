import * as types from './bnbActionTypes';

const initialState = {
  bnb: null,
  error: {
    type: null,
    data: null,
  },
};

export const bnbReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case types.GET_BNB_DATA:
      return {
        ...state,
        bnb: action?.payload.result,
      };
    case types.GET_BNB_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_BNB_ERROR,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};
