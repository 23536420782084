import React from 'react';
import { connect } from 'react-redux';
import './MercurXContractDetail.scss';
import { Accordion } from 'react-bootstrap';
import { useLocation } from 'react-router';

const MercurXContractDetail = (props) => {
  const {
  } = props;
  const location = useLocation();
  const { itemName, item } = location.state || {};
console.log(item)
return (
  <div className="backofficeUser-detail">
    <Accordion className="mt-5 shadow" alwaysOpen>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <div className="text-fs-head-sm">{itemName}</div>
        </Accordion.Header>
        <Accordion.Body>
          {item?.data?.data ? (
            <ul>
              {item.data.data.map((fn, index) => (
                <li key={index}>
                  <strong>Function Name:</strong> {fn.name} <br />
                  <strong>Type:</strong> {fn.type} <br />
                  <strong>State Mutability:</strong> {fn.stateMutability || 'N/A'} <br />
                  {fn.inputs?.length > 0 && (
                    <>
                      <strong>Inputs:</strong>
                      <ul>
                        {fn.inputs.map((input, i) => (
                          <li key={i}>
                            {input.name} ({input.type})
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  {fn.outputs?.length > 0 && (
                    <>
                      <strong>Outputs:</strong>
                      <ul>
                        {fn.outputs.map((output, i) => (
                          <li key={i}>
                            {output.name || 'Unnamed'} ({output.type})
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  <hr />
                </li>
              ))}
            </ul>
          ) : (
            <p>No function data available.</p>
          )}
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <div className="text-fs-head-sm">Contract Code</div>
        </Accordion.Header>
        <Accordion.Body>
          {item?.data?.code ? (
            <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
              {item.data.code}
            </pre>
          ) : (
            <p>No contract code available.</p>
          )}
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <div className="text-fs-head-sm">Bytecode</div>
        </Accordion.Header>
        <Accordion.Body>
          {item?.data?.byte_code ? (
            <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
              {item.data.byte_code}
            </pre>
          ) : (
            <p>No bytecode available.</p>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </div>
);

};

const mapStateToProps = (state) => {
  return {
    // token: state.tokenReducer.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MercurXContractDetail);
