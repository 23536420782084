import { takeEvery, call, put, all } from 'redux-saga/effects';

import * as types from './tokenActionTypes';
import * as actions from './tokenActions';
import * as endpoints from '../../services/endpoints';
import * as alert from '../alert/alertActions';
import { errorHandler } from '../../helpers/errorHandler';
//Worker Sagas

function* getTokensSaga(action) {
  try {
    const { data } = yield call(endpoints.getTokens);
    yield put(actions.getTokensActionData(data));
    yield put(actions.sortTokensAction());
  } catch (e) {
    yield put(actions.getTokensActionError(e));
  }
}

function* createTokenSaga(action) {
  try {
    const { body } = action.payload;
    console.log(body);
    const { data } = yield call(endpoints.createToken, body);
    yield put(actions.createTokenActionData(data));
    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: data.message,
        variant: 'success',
      }),
    );
  } catch (e) {
    errorHandler(e) 
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.message,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.createTokenActionError(e));
  }
}

function* updateTokenSaga(action) {
  try {
    const { tokenID, body } = action.payload;
    console.log(tokenID);
    console.log(body);
    const { data } = yield call(endpoints.updateToken, tokenID, body);
    yield put(actions.updateTokenActionData(data));
    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: data.message,
        variant: 'success',
      }),
    );
  } catch (e) {
    errorHandler(e) 
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.message,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.updateTokenActionError(e));
  }
}

function* deleteTokenSaga(action) {
  try {
    const { tokenID } = action.payload;
    console.log('deleteTokenSaga: ', tokenID);
    const { data } = yield call(endpoints.deleteToken, tokenID);
    yield put(actions.deleteTokenActionData(tokenID));
    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: data.message,
        variant: 'success',
      }),
    );
  } catch (e) {
    errorHandler(e) 
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.message,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.deleteTokenActionError(e));
  }
}

//Watcher Sagas
function* watchGetTokens() {
  yield takeEvery(types.GET_TOKENS_REQUEST, getTokensSaga);
}
function* watchCreateToken() {
  yield takeEvery(types.CREATE_TOKEN_REQUEST, createTokenSaga);
}

function* watchUpdateToken() {
  yield takeEvery(types.UPDATE_TOKEN_REQUEST, updateTokenSaga);
}

function* watchDeleteToken() {
  yield takeEvery(types.DELETE_TOKEN_REQUEST, deleteTokenSaga);
}

export function* tokenSaga() {
  yield all([watchGetTokens(), watchDeleteToken(), watchCreateToken(), watchUpdateToken()]);
}
