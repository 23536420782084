import {
  PROJECTS_SORT_KEYS,
  PROJECTS_SORT_TYPES,
  projectsQuickFilterConstants,
  sortKeys,
} from '../../pages/Projects/projectsConstants';
import * as types from './projectActionTypes';

const initialState = {
  projects: null,
  filteredProjects: null,
  project: null,
  createdProject: null,
  updatedProject: null,
  deletedProject: null,
  projectsSortData: {
    sortType: PROJECTS_SORT_TYPES.ASCENDING,
    sortKey: PROJECTS_SORT_KEYS.TOKEN_NAME,
  },
  quickFilter: 1,
  error: {
    type: null,
    data: null,
  },
};

export const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PROJECTS_DATA:
      let projects = action.payload.result;
      console.log(projects)
      return {
        ...state,
        projects: projects ? [...projects] : null,
      };
    case types.GET_PROJECTS_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_PROJECTS_ERROR,
          data: action.payload,
        },
      };
    case types.CREATE_PROJECT_DATA:
      return {
        ...state,
        createdProjet: action.payload,
      };
    case types.CREATE_PROJECT_ERROR:
      return {
        ...state,
        error: {
          type: types.CREATE_PROJECT_ERROR,
          data: action.payload,
        },
      };
    case types.UPDATE_PROJECT_DATA:
      return {
        ...state,
        updatedProject: action.payload,
      };
    case types.UPDATE_PROJECT_ERROR:
      return {
        ...state,
        error: {
          type: types.UPDATE_PROJECT_ERROR,
          data: action.payload,
        },
      };
    case types.DELETE_PROJECT_DATA:
      const deletedProjectID = action.payload;

      let tempProjectsForDelete = state.projects;
      const deletedProjectIndex = tempProjectsForDelete.findIndex(
        (p) => p.id === deletedProjectID,
      );
      tempProjectsForDelete.splice(deletedProjectIndex, 1);

      let tempFilteredProjectForDelete = state.filteredProjects;
      if (tempFilteredProjectForDelete) {
        const deletedFilteredProjectIndex = tempFilteredProjectForDelete.findIndex(
          (p) => p.id === deletedProjectID,
        );
        tempFilteredProjectForDelete.splice(deletedFilteredProjectIndex, 1);
      }

      return {
        ...state,
        projects: tempProjectsForDelete ? [...tempProjectsForDelete] : state.projects,
        filteredProjects: tempFilteredProjectForDelete ? [...tempFilteredProjectForDelete] : null,
        deletedProject: action.payload,
      };
    case types.DELETE_PROJECT_ERROR:
      return {
        ...state,
        error: {
          type: types.DELETE_PROJECT_ERROR,
          data: action.payload,
        },
      };
    case types.FILTER_PROJECTS:
      function filterProjects(filterInput) {
        console.log(filterInput)
        const filteredProjects = [];

        let tempProjectsForFilter = state.projects;
        if (state.quickFilter === projectsQuickFilterConstants.ACTIVE) {
          tempProjectsForFilter = tempProjectsForFilter.filter((t) => t.is_active === 'active');
        } else if (state.quickFilter === projectsQuickFilterConstants.COMPLETED) {
          tempProjectsForFilter = tempProjectsForFilter.filter((t) => t.is_active === 'completed');
        }

        if (tempProjectsForFilter) {
          for (const fProjects of tempProjectsForFilter) {
            if (
              fProjects?.token?.name?.toString().toLowerCase().includes(filterInput) ||
              fProjects?.token?.symbol?.toString().toLowerCase().includes(filterInput) ||
              fProjects?.token?.address?.toString().toLowerCase().includes(filterInput)
            ) {
              filteredProjects.push(fProjects);
            }
          }

          return filteredProjects;
        }
      }

      let filteredProjects = null;
    
      filteredProjects = filterProjects(action?.payload?.toString().toLowerCase());

      return {
        ...state,
        filteredProjects: filteredProjects ? [...filteredProjects] : null,
      };
    case types.SET_PROJECTS_SORT_DATA:
      return {
        ...state,
        projectsSortData: { ...action.payload },
      };
    case types.SORT_PROJECTS:
      const selectedKey = sortKeys[state.projectsSortData.sortKey].key;
      const tempProjectsForSorting = state.filteredProjects?.length
        ? state.filteredProjects
        : state.projects;
        console.log("Temp Locks For Sorting:", tempProjectsForSorting);

      const sortedProjects = tempProjectsForSorting?.sort((a, b) => {
        if (state.projectsSortData.sortType === PROJECTS_SORT_TYPES.ASCENDING) {
          return (
            a.token[selectedKey]
              ?.toString()
              .toLowerCase()
              .localeCompare(b.token[selectedKey]?.toString().toLowerCase())
          );
        } else {
          return (
            b.token[selectedKey]
              ?.toString()
              .toLowerCase()
              .localeCompare(a.token[selectedKey]?.toString().toLowerCase())
          );
        }
      });

      if (state.filteredProjects?.length) {
        return {
          ...state,
          filteredProjects: sortedProjects ? [...sortedProjects] : null,
        };
      }
      return {
        ...state,
        projects: sortedProjects ? [...sortedProjects] : state.projects,
      };
    case types.UPDATE_QUICK_FILTER:
      return {
        ...state,
        quickFilter: action?.payload,
      };
    default:
      return state;
  }
};
